import { capitalize, compact, groupBy } from "lodash";

import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";

import {
  countPackageAppointments,
  frequencyCopies,
} from "@components/Package/utils";
import {
  formatDuration,
  formatInMinutes,
  getPackageInstanceHelper,
  getRecurringCycleTotal,
  getTotalAppointmentsConsumed,
} from "@components/PackageInstance/utils";

export type PackageInstancesAppointmentsMapType = Record<
  string,
  {
    items: PackageInstanceType["items"];
    quantity: number | string;
    appointments: AppointmentType[];
    packageInstance: PackageInstanceType;

    // used to store the index of the appointment in the package instance
    [appointmentId: string]: any;
  }
>;

export const countPackageInstancesAppointmentsMap = (
  packageInstances: PackageInstanceType[] | undefined,
  appointments: AppointmentType[] | undefined
) => {
  const packageInstancesAppointmentsMap: PackageInstancesAppointmentsMapType =
    {};
  if (packageInstances?.length && appointments?.length) {
    packageInstances?.forEach((packageInstance) => {
      const packageInstanceId = packageInstance?.id || "";
      const { contentType, distributeSessions } =
        getPackageInstanceHelper(packageInstance);

      packageInstancesAppointmentsMap[packageInstanceId] = {
        appointments: [],
        quantity: 0,
        items: packageInstance?.items,
        packageInstance: {
          ...packageInstance,
          contentType,
          distributeSessions,
        },
      };

      if (packageInstance?.items?.length) {
        packageInstancesAppointmentsMap[packageInstanceId].quantity =
          countPackageAppointments(packageInstance, null);
      }
    });
    appointments?.sort((a, b) => a?.start - b?.start);
    appointments?.forEach((appointment) => {
      const appointmentPackageInstanceId = appointment?.packageInstanceId || "";
      const appointmentId = appointment?.id;

      if (appointmentPackageInstanceId) {
        const currentPackageInstance =
          packageInstancesAppointmentsMap[appointmentPackageInstanceId];

        packageInstancesAppointmentsMap[
          appointmentPackageInstanceId
        ]?.appointments.push(appointment);

        // @TODO: Ask the team how can we improve this. We currently save the
        //        map expeting its value as an object, but this logic is also
        //        storing the current index of an appointment to display what
        //        order it should be show in the client record, and probably
        //        other part of the system as well.
        if (currentPackageInstance && currentPackageInstance.quantity) {
          const packageInstance = currentPackageInstance?.packageInstance;
          const { isPackageTypeRecurring, isRollOver } =
            getPackageInstanceHelper(packageInstance);
          const appointments = currentPackageInstance?.appointments;
          const recurringAppointmentPackages = groupBy(
            appointments,
            (appt) => appt?.packageInstanceCycle
          );
          const mapIds = (appt: AppointmentType) => appt.id;
          const appointmentIds =
            isPackageTypeRecurring &&
            !isRollOver &&
            appointment?.packageInstanceCycle
              ? recurringAppointmentPackages[
                  appointment?.packageInstanceCycle
                ]?.map(mapIds)
              : appointments?.map(mapIds);
          const index = appointmentIds.indexOf(appointmentId) + 1;

          packageInstancesAppointmentsMap[appointmentPackageInstanceId][
            appointmentId
          ] = index;
        }
      }
    });
  }
  return packageInstancesAppointmentsMap;
};

/**
 * Returns the appointment duration in human readable format
 * */
const getPackageInstanceTagTimeBased = (
  appointment: AppointmentType,
  packageInstance: PackageInstanceType
) => {
  const { timeType, isPackageTypeRecurring, isRollOver } =
    getPackageInstanceHelper(packageInstance);
  const frequencyType = packageInstance?.frequency?.type;

  const isHourBased = timeType === "hours";
  const getIsoStringDate = (data: any) =>
    data?.toISOString ? data?.toISOString() : data;
  const normalizedAppointment = {
    ...appointment,
    start: getIsoStringDate(appointment?.start),
    end: getIsoStringDate(appointment?.end),
  } as unknown as AppointmentType;
  const appointmentDurationInMinutes = getTotalAppointmentsConsumed(
    [normalizedAppointment],
    "minutes"
  );
  const formattedDuration =
    isHourBased && appointmentDurationInMinutes > 60
      ? formatDuration(appointmentDurationInMinutes)
      : formatInMinutes(appointmentDurationInMinutes);

  const shouldRenderRecurringPrefix =
    isPackageTypeRecurring &&
    !isRollOver &&
    frequencyType &&
    appointment?.packageInstanceCycle;

  return compact([
    shouldRenderRecurringPrefix &&
      capitalize(frequencyCopies[frequencyType].singular),
    shouldRenderRecurringPrefix && `${appointment.packageInstanceCycle}:`,
    formattedDuration,
  ]).join(" ");
};

const getPackageInstanceTagRecurringBased = (
  packageInstance: PackageInstanceType,
  appointments: AppointmentType[],
  appointmentId: string,
  currentStep: number,
  totalSteps: number,
  isUsagePackage?: boolean
) => {
  const appointment = appointments.find(
    (appointment) => appointment.id === appointmentId
  );
  const frequencyType = packageInstance?.frequency?.type || "months";
  const frequencyTypeFormatted = capitalize(
    frequencyCopies[frequencyType].singular
  );
  const currentCycle = appointment?.packageInstanceCycle || 1;
  if (isUsagePackage) return `${frequencyTypeFormatted} ${currentCycle}`;
  return `${frequencyTypeFormatted} ${currentCycle}: ${currentStep}/${totalSteps}`;
};

export const getPackageInstanceTag = (
  packageInstanceId: string,
  appointmentId: string,
  packageInstancesAppointmentsMap: PackageInstancesAppointmentsMapType,
  cycle?: number | null
) => {
  if (!packageInstanceId || !appointmentId) return;
  let currentStep;
  let totalSteps;

  const currentItem = packageInstancesAppointmentsMap?.[packageInstanceId];

  if (!currentItem) return;

  const currentItemsExists =
    !!currentItem && !!Object.keys(currentItem)?.length;

  const packageInstance = currentItem.packageInstance;
  const {
    isRollOver,
    distributeSessions,
    isPackageTypeRecurring,
    isContentTypeTime,
    isUsagePackage,
  } = getPackageInstanceHelper(packageInstance);

  if (currentItemsExists) {
    currentStep = currentItem[appointmentId];
    totalSteps = currentItem.quantity;
  }

  if (currentItemsExists && !distributeSessions) {
    const currentAppointment = currentItem.appointments.find(
      (appointment) => appointment.id === appointmentId
    );
    const currentSchedulerId = currentAppointment?.availabilityId;
    const currentScheduler = packageInstance?.items?.find(
      (item) => item.schedulerId === currentSchedulerId
    );
    const appointmentIds = currentItem.appointments
      .filter(
        (appointment) => appointment.availabilityId === currentSchedulerId
      )
      .map((appointment) => appointment.id);
    currentStep = appointmentIds.indexOf(appointmentId) + 1;
    totalSteps = currentScheduler?.quantity || 0;
  }

  if (isContentTypeTime) {
    const appointment = currentItem.appointments.find(
      (appointment) => appointment.id === appointmentId
    );
    return getPackageInstanceTagTimeBased(
      appointment as AppointmentType,
      packageInstance
    );
  }

  if ((isPackageTypeRecurring && !isRollOver) || isUsagePackage) {
    totalSteps = getRecurringCycleTotal(packageInstance, cycle);
    return getPackageInstanceTagRecurringBased(
      packageInstance,
      currentItem.appointments,
      appointmentId,
      currentStep,
      totalSteps as number,
      isUsagePackage
    );
  }

  if (isRollOver) {
    totalSteps = packageInstance?.totalSessions || 0;
  }

  return currentStep && totalSteps && `${currentStep}/${totalSteps}`;
};
