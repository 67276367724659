import { where } from "firebase/firestore";

import { useGetFirestoreCollectionData } from "@contexts/data";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";

type usePackageInstancesType = (
  coachUserId?: string,
  clientId?: string,
  parentClientId?: string
) => {
  packageInstances?: PackageInstanceType[];
  loading: boolean;
};

const usePackageInstances: usePackageInstancesType = (
  coachUserId,
  clientId,
  parentClientId
) => {
  const whereClause: Parameters<typeof where>[] = [
    ["status", "in", ["active", "completed", "blocked"]],
  ];

  const clientIds: string[] = [];
  if (clientId) clientIds.push(clientId);
  if (parentClientId) clientIds.push(parentClientId);

  if (clientIds.length > 0) whereClause.push(["clientId", "in", clientIds]);

  const { data: data, error: error } = useGetFirestoreCollectionData(
    coachUserId && clientId
      ? `users/${coachUserId}/packageInstances`
      : undefined,
    whereClause,
    ["createdAt", "desc"]
  );

  return {
    packageInstances: data as PackageInstanceType[],
    loading: !data && !error,
  };
};

export default usePackageInstances;
