import { useMemo } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { getPackageInstanceAppointments } from "api-services/definitions/package-instances";
import { useApiGet } from "api-services/endpoints";
import axios from "axios";
import classNames from "classnames";
import { compact } from "lodash";
import { DateTime, Interval } from "luxon";
import Link from "next/link";
import { useRouter } from "next/router";

import { useParentContact } from "@hooks/data/clients";
import useLinkPackage from "@hooks/use-link-package";
import usePackageInstance from "@hooks/use-package-instance";
import usePackageInstances from "@hooks/use-package-instances";
import { usePackageSchedulers } from "@hooks/use-package-schedulers";
import { usePackages } from "@hooks/use-packages";
import useToggle from "@hooks/use-toggle";
import {
  decoratedAppointmentStatus,
  formatCountAttendeesTitle,
} from "@lib/appointments";
import { displayNameFromContact } from "@lib/contacts";
import {
  countPackageInstancesAppointmentsMap,
  getPackageInstanceTag,
} from "@lib/packages/package-instances";
import { FormattedPaginatedAppointment } from "@lib/utils/appointments/formatAppointment";
import pluralHelper from "@lib/utils/pluralHelper";

import ArtifactStatusIcon, {
  ArtifactStatusText,
} from "@components/ArtifactStatusIcon";
import ClientAvatar from "@components/Client/ClientAvatar";
import { DropdownItem } from "@components/Dropdowns/SecondaryDropdown";
import IconAction from "@components/IconAction";
import EventExternalIcon from "@components/Icons/EventExternalIcon";
import EventsIcon from "@components/Icons/EventsIcon";
import GroupIcon from "@components/Icons/GroupIcon";
import PencilIcon from "@components/Icons/PencilIcon";
import ShareIcon from "@components/Icons/ShareIcon";
import AssignPackageSchedulerModal from "@components/Package/AssignPackageSchedulerModal";
import Tag from "@components/Tag/Tag";
import TypeIllustration from "@components/TypeIllustration";

export interface AppointmentListItemProps {
  appointment: FormattedPaginatedAppointment;
  organizationId: string;
  showContact?: boolean;
  variant?: string;
  shouldDisplayOrganizer?: boolean;
  onShare: (appt: FormattedPaginatedAppointment) => void;
}

const AppointmentListItem = ({
  appointment,
  showContact,
  variant,
  shouldDisplayOrganizer,
  organizationId,
  onShare,
}: AppointmentListItemProps) => {
  const { packageInstanceId, contactId } = appointment;
  const router = useRouter();
  const { value: showAssignPackageModal, toggle: toggleAssignPackageModal } =
    useToggle();
  const { packages } = usePackages();
  const { parentContact } = useParentContact(contactId);
  const { packageInstances } = usePackageInstances(
    organizationId,
    contactId,
    parentContact?.id
  );
  const hasPackageInstances = packageInstances && packageInstances?.length > 0;
  const { link } = useLinkPackage({
    coachId: organizationId,
    clientId: contactId,
  });
  const { packageSchedulers } = usePackageSchedulers(
    contactId,
    undefined,
    undefined,
    parentContact?.id
  );
  const { packageInstance } = usePackageInstance(
    organizationId,
    packageInstanceId
  );
  const { data } = useApiGet(
    getPackageInstanceAppointments,
    {
      packageInstanceId,
      userId: organizationId,
      clientId: contactId,
    },
    {}
  );
  const packageInstanceAppointments = data?.packageInstanceAppointments;

  const packageInstancesAppointmentsMap = useMemo(
    () =>
      countPackageInstancesAppointmentsMap(
        [packageInstance],
        packageInstanceAppointments
      ),
    [packageInstance, packageInstanceAppointments]
  );

  const appointmentStatus = decoratedAppointmentStatus(appointment);

  const title = appointment.title;
  let date = DateTime.fromISO(appointment.ISOstart).toLocaleString(
    DateTime.DATE_MED_WITH_WEEKDAY
  );
  const startDateTime = DateTime.fromISO(appointment.ISOstart);
  const start = startDateTime.toLocaleString(DateTime.TIME_SIMPLE);
  const endDateTime = DateTime.fromISO(appointment.ISOend);
  const end = endDateTime.toLocaleString(DateTime.TIME_SIMPLE);
  const duration = Interval.fromDateTimes(startDateTime, endDateTime).length(
    "minutes"
  );
  const isPast = DateTime.now() > endDateTime;
  const appointmentId = appointment.id;
  let url = `/appointments/${encodeURIComponent(appointmentId)}`;
  if (appointment.directFromCalendar) {
    url += `?contactId=${appointment.contacts?.at(0)?.id ?? ""}`;
  }
  let subTitle = appointment.allDayEvent
    ? "All day"
    : `${start} - ${end} ${packageInstance ? `(${duration} min)` : ""}`;
  const isVariantRecord = variant === "record";
  //If full days(s)
  if (appointment.ISOend === undefined && appointment.ISOstart === undefined) {
    const start_updated = DateTime.fromISO(
      appointment.start + "T12:00:00-00:00"
    );
    const end_updated = DateTime.fromISO(appointment.end + "T12:00:00-00:00");
    const start_weekday = start_updated.toLocaleString(
      DateTime.DATE_MED_WITH_WEEKDAY
    );
    const startMonth = start_updated.monthShort;
    const startDay = start_updated.day;
    const endMonth = end_updated.monthShort;
    const endDay = end_updated.day;
    const endYear = end_updated.year;
    subTitle =
      startMonth +
      " " +
      startDay +
      " - " +
      endMonth +
      " " +
      endDay +
      ", " +
      endYear;
    date = start_weekday;
  }

  const shouldDisplayContact =
    showContact && (appointment.contacts?.length ?? 0) > 0;
  const shouldDisplayEvent = showContact && !!appointment?.eventData;
  const shouldDisplayGroup = showContact && !!appointment?.group;

  const shouldDisplayAppointment =
    !shouldDisplayEvent &&
    !shouldDisplayGroup &&
    !!appointment?.contactId &&
    !packageInstanceId;
  const attendeeCount = appointment?.customEventInformation
    ? 1
    : (appointment?.gcal?.event?.attendees?.length || 2) - 1;
  const Icon = shouldDisplayGroup ? GroupIcon : EventsIcon;
  const apptTitle = shouldDisplayGroup
    ? pluralHelper(attendeeCount, "client")
    : "Event";

  const linkAppt = async (
    packageInstanceId: string,
    packageInstanceOwnerId: string,
    appointmentId: string,
    schedulerId?: string,
    cycle?: number
  ) => {
    await link({
      appointmentId,
      packageInstanceId,
      packageInstanceOwnerId,
      availabilityId: schedulerId,
      cycle,
    });
  };

  const renderAttendeesInfo = () => {
    const shouldRender =
      shouldDisplayContact || shouldDisplayEvent || shouldDisplayGroup;
    const data = {
      title: shouldDisplayContact
        ? displayNameFromContact(appointment.contacts?.at(0))
        : shouldDisplayGroup
        ? appointment.group?.title || "Group appointment"
        : formatCountAttendeesTitle(appointment, appointment.contacts ?? []),
      subtitle:
        shouldDisplayOrganizer && appointment.organizer ? (
          <div className="flex items-center">
            <ClientAvatar
              size="xsmall"
              className="!mx-0"
              client={appointment.organizer}
            />
            <span className="text-xs font-medium ml-2">
              {displayNameFromContact(appointment.organizer)}
            </span>
          </div>
        ) : shouldDisplayContact ? (
          appointment.contacts?.at(0)?.email
        ) : (
          <p className="flex items-center">
            <Icon className="w-5 h-5 mr-1" /> {apptTitle}
          </p>
        ),
    };
    return (
      shouldRender && (
        <div className="hidden md:block">
          <div className="truncate text-black-ink">{data.title}</div>
          <div className="truncate text-sm text-grey-500">{data.subtitle}</div>
        </div>
      )
    );
  };

  const renderPackageSubtitle = () => {
    if (!packageInstance) return null;
    const { icon, title, id } = packageInstance;
    const packageInstanceTag = getPackageInstanceTag(
      id,
      appointment?.id,
      packageInstancesAppointmentsMap,
      appointment?.packageInstanceCycle
    );
    return (
      <div className="flex gap-2 items-center">
        <TypeIllustration slug={icon} width={24} height={24} />
        {title}
        {packageInstanceTag && <Tag>{packageInstanceTag}</Tag>}
      </div>
    );
  };

  const renderEventActions = () => {
    const options: DropdownItem[] = compact([
      !isPast && {
        type: "button",
        title: "Share",
        Icon: ShareIcon,
        onClick: () => onShare(appointment),
      },
      !isPast && {
        type: "button",
        title: "Edit",
        Icon: PencilIcon,
        onClick: () => router.push(`/event/${appointment.id}/edit`),
      },
      {
        type: "delete",
        onDelete: () => {
          axios.post(`/api/users/${organizationId}/appointments/cancel`, {
            appointmentId: appointment.id,
          });
        },
        artifactType: "event",
      },
    ]);

    return (
      shouldDisplayEvent && (
        <IconAction
          className="top-0 bottom-0 right-4 m-auto"
          type="dropdown"
          dropdownItems={options}
        />
      )
    );
  };

  const renderAppointmentActions = () => {
    if (!hasPackageInstances) return null;
    const options: DropdownItem[] = compact([
      {
        type: "button",
        title: "Add to package",
        Icon: EventExternalIcon,
        onClick: () => toggleAssignPackageModal(),
      },
    ]);

    return (
      shouldDisplayAppointment && (
        <IconAction
          className="top-0 bottom-0 right-4 m-auto"
          type="dropdown"
          dropdownItems={options}
        />
      )
    );
  };

  const renderAssignPackageModal = () => {
    return (
      showAssignPackageModal && (
        <AssignPackageSchedulerModal
          packages={packages ?? []}
          packageInstances={packageInstances ?? []}
          packageSchedulers={packageSchedulers ?? []}
          toggleShow={toggleAssignPackageModal}
          show
          appointment={appointment}
          linkAppt={linkAppt}
          packageAppointments={
            packageInstanceId
              ? packageInstancesAppointmentsMap[packageInstanceId]?.appointments
              : []
          }
        />
      )
    );
  };

  return (
    <li className="border-t first:border-t-0 border-gray-200 group relative">
      <Link
        href={url}
        className="block hover:bg-action-900 focus:outline-none focus:bg-action-900 transition duration-150 ease-in-out"
      >
        <div className="flex items-center py-4 sm:px-4">
          <div
            className={classNames(
              "min-w-0 flex-1 grid grid-cols-2 gap-4",
              showContact ? "md:grid-cols-3" : "md:grid-cols-2"
            )}
          >
            <div className="flex items-center">
              <ArtifactStatusIcon
                status={shouldDisplayEvent ? "shared" : appointmentStatus}
                type="appointments"
                variant="status-icon"
                className="mr-4"
                outcome={appointment.appointmentOutcome}
              />
              <div className="truncate">
                <div className="truncate text-black-ink">
                  {isVariantRecord ? title : date}
                </div>
                <div
                  className={classNames(
                    "truncate text-sm",
                    variant !== "record" && "text-grey-500"
                  )}
                >
                  {isVariantRecord ? (
                    <ArtifactStatusText
                      status={appointmentStatus}
                      type="appointments"
                      size="sm"
                    />
                  ) : (
                    subTitle
                  )}
                </div>
              </div>
            </div>

            <div
              className={`${
                shouldDisplayContact || shouldDisplayEvent ? "hidden" : "block"
              } md:block`}
            >
              <div className="truncate text-black-ink">
                {isVariantRecord ? date : title}
              </div>
              <div className="truncate text-sm text-grey-500">
                {isVariantRecord
                  ? subTitle
                  : packageInstance
                  ? renderPackageSubtitle()
                  : "No Package"}
              </div>
            </div>
            {shouldDisplayContact || shouldDisplayEvent ? (
              <div className="md:hidden text-right">
                <div className="truncate text-black-ink">
                  {shouldDisplayContact
                    ? displayNameFromContact(appointment.contacts?.at(0))
                    : formatCountAttendeesTitle(
                        appointment,
                        appointment.contacts ?? []
                      )}
                </div>
                <div className="truncate text-grey-500 text-sm">
                  {duration} {title}
                </div>
              </div>
            ) : null}
            {renderAttendeesInfo()}
          </div>
        </div>
      </Link>
      {renderEventActions()}
      {renderAppointmentActions()}
      {renderAssignPackageModal()}
    </li>
  );
};

export default withErrorBoundary(AppointmentListItem, { fallback: null });
