import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { isEmpty } from "lodash";

import SmallModal, { SmallModalProps } from "@components/Modals/SmallModal";

export interface FormWrappedSmallModalProps extends SmallModalProps {
  defaultValues: any;
}

const FormWrappedSmallModal: FC<FormWrappedSmallModalProps> = ({
  onAction,
  defaultValues,
  ...rest
}) => {
  const formMethods = useForm({
    mode: "onTouched",
    shouldUnregister: false,
    defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <SmallModal
        onAction={formMethods.handleSubmit(onAction)}
        onActionLoading={formMethods.formState.isSubmitting}
        isActionDisabled={!isEmpty(formMethods.formState.errors)}
        {...rest}
      />
    </FormProvider>
  );
};

export default FormWrappedSmallModal;
