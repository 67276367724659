import { FC, FormEvent, useMemo, useState } from "react";
import { PackageSchedulerType } from "api-services/definitions/package-instances";

import { useAuth } from "@contexts/auth";
import useSnackbar from "@hooks/use-snackbar";
import { useUpdateAppointmentsCache } from "@hooks/useUpdateAppointmentsCache";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";
import { PackageType } from "@lib/data/schemas/packages";

import EventAfterIcon from "@components/Icons/EventAfterIcon";
import FormWrappedSmallModal from "@components/Modals/FormWrappedSmallModal";
import { getPackageInstanceInfo } from "@components/PackageInstance/utils";
import { progressText } from "@components/PackageInstances/PackageProgress/utils";
import { OptionType } from "@components/Select/Option";
import FormSelect from "@components/Select/Select/FormSelect";

import CyclePicker from "./CyclePicker";

type FormDataType = {
  packageInstanceId: string;
  schedulerId: string;
  cycle: number;
};

interface AssignPackageModalProps {
  show: boolean;
  toggleShow: (val: boolean) => void;
  appointment: AppointmentType;
  packages: PackageType[];
  packageInstances: Array<
    PackageInstanceType & {
      ownerId?: string;
    }
  >;
  packageSchedulers: PackageSchedulerType[];
  linkAppt: (
    packageInstanceId: string,
    packageInstanceOwnerId: string,
    appointmentId: string,
    schedulerId: string,
    cycle: number
  ) => Promise<void>;
  packageAppointments?: AppointmentType[];
}

const AssignPackageSchedulerModal: FC<AssignPackageModalProps> = ({
  show,
  toggleShow,
  appointment,
  packages,
  packageInstances,
  packageSchedulers,
  linkAppt,
  packageAppointments,
}) => {
  const { oid } = useAuth();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [packageInstanceId, setPackageInstanceId] = useState<string | null>(
    null
  );
  const [schedulerId, setSchedulerId] = useState<string | null>(null);
  const appointmentId = appointment?.id;

  const { onUpdateAppointment } = useUpdateAppointmentsCache();

  const filteredPackages = useMemo(() => {
    return packageInstances
      .filter((packageInstance: PackageInstanceType) => {
        const schedulers = packageSchedulers?.filter(
          (schedler) => schedler.packageInstanceId === packageInstance.id
        );

        const { isCompleted } = getPackageInstanceInfo(
          packageInstance,
          schedulers,
          true
        );

        return packageInstance.status === "active" && !isCompleted;
      })
      .map((packageObject: PackageInstanceType) => {
        const schedulers = packageSchedulers?.filter(
          (schedler) => schedler.packageInstanceId === packageObject.id
        );

        const description = progressText(packageObject, schedulers);

        return {
          value: packageObject?.id,
          title: packageObject?.title,
          description,
          icon: packageObject?.icon,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [packages, packageInstances, packageSchedulers]);

  const filteredSchedulers = useMemo(() => {
    return packageSchedulers?.filter((scheduler) => {
      return scheduler.packageInstanceId === packageInstanceId;
    });
  }, [packageSchedulers, packageInstanceId]);

  const packageInstance = packageInstances.find(
    (packageObject) => packageObject.id === packageInstanceId
  );

  const canAssignToPackage =
    packageInstanceId && schedulerId && filteredSchedulers?.length;

  const onSubmit = async (data: FormDataType, e: FormEvent) => {
    setLoading(true);

    const packageInstanceOwnerId =
      packageInstance?.ownerId ?? appointment.contactId;

    if (!oid || !packageInstanceOwnerId) return;

    e.preventDefault();
    try {
      await linkAppt(
        data.packageInstanceId,
        packageInstanceOwnerId,
        appointmentId,
        data.schedulerId,
        data.cycle
      );
      await onUpdateAppointment(appointmentId);
      snackbar.showMessage("Appointment assigned to package");
    } catch (e) {
      snackbar.showWarning(
        "Error occurred",
        "Assigning package failed, please try again!"
      );
    } finally {
      setLoading(false);
      toggleShow(false);
    }
  };

  return (
    <FormWrappedSmallModal
      show={show}
      toggleShow={toggleShow}
      title="Add appointment to package"
      description="Select the scheduler this appointment will be assigned to. It will update the specific scheduler and package counter."
      icon={EventAfterIcon}
      iconColor="green"
      heapModalName="assign_package_scheduler_modal"
      onActionText="Assign to package"
      onAction={onSubmit}
      defaultValues={{
        schedulerId: "",
      }}
      onActionLoading={loading}
      isActionDisabled={!canAssignToPackage}
      isCloseAfterPrimaryActionDisabled
    >
      <div className="flex flex-col gap-4">
        <FormSelect
          name="packageInstanceId"
          placeholder="Choose a package"
          simpleSearchPlaceholder="Search for packages..."
          options={filteredPackages as unknown as OptionType[]}
          onValueChange={setPackageInstanceId}
          required
        />
        {packageInstanceId && filteredSchedulers?.length && (
          <>
            <FormSelect
              name="schedulerId"
              placeholder="Choose a scheduler"
              simpleSearchPlaceholder="Search for schedulers..."
              options={filteredSchedulers?.map((scheduler) => ({
                value: scheduler.id,
                title: scheduler.title,
                description: `${scheduler.duration} minutes`,
                icon: scheduler.icon,
              }))}
              onValueChange={setSchedulerId}
              required
            />
            {packageInstance?.frequency && (
              <CyclePicker
                packageInstance={packageInstance}
                appointment={appointment}
                startDate={packageInstance.startDate}
                name="cycle"
                existingAppointments={packageAppointments}
              />
            )}
          </>
        )}
      </div>
    </FormWrappedSmallModal>
  );
};

export default AssignPackageSchedulerModal;
